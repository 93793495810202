.menunav {
	@include prop(padding, 5px 0 1px, $vert/3 0, $vert*.75 0, $vert*.75 0);
	background: lighten($grey, 83%);


	& > div.full {
		text-align: center;
		@include prop(margin, $vert/3 $vert/2 $vert/6, 0 $vert, 0 20px, $vert/2 $vert*2);
	}
	li {
		display: inline-block;
		margin: 20px 0;

		@include phone {
			margin: 11px 0;
		}
	}
	li a {
		position: relative;
		@include prop(margin, 10px 5px, 10px 10px, 20px 30px, 20px 30px);
		@include type(13,15,20,24,1.7,$sans);
		&:after{
			content: ' ';
			position: absolute;
			display: block;
			z-index: 9999;
			width: 4px;
			height: 4px;
			background: black;
			left: 50%;
			bottom: 0px;
			border-radius: 3px;
			transform: translateY(15px) translateX(-50%) rotateY(90deg);
			transition: all $cubic .2s;
		}
	}


		a.active:after, a:hover:after {
			background: black;
			transform: translateY(15px) translateX(-50%);
		}


	// @include phone, tablet, desktop {
	// 			flex: 1 !important;
	// 		}
}

.menus {
	@include prop(padding, $vert/1.5 0 $vert, $vert 0, $vert*1.25 0, $vert*1.4 0);
	
	.listing {
		@include prop(padding, 0 $vert/2, 0 10%, 0 20%, 0 30%);

		ul.smaller-starter-plates .price {
			@include prop(margin-top, -20px, -18px, -20px, -25px);
		}
		
		ul.eggs-based-dishes .price {
			@include prop(margin-top, -20px, -18px, -20px, -25px);		}
		
		ul.lunchier-dishes .price {
			@include prop(margin-top, -20px, -18px, -20px, -25px);
		}
		
		ul.extras .price {
			@include prop(margin-top, -20px, -18px, -20px, -28px);
		}
		
		ul.drinks .price {
			@include prop(margin-top, -22px, -23px, -23px, -27px);
		}

		li {
			
			border-top:1px solid lighten($grey, 80%);
			@include prop(padding, 20px, 20px 5%, 20px 5%, 20px 5%);

			@include type(18,18,20,24,1.2,$sans);

			&:last-child {
				border-bottom:1px solid lighten($grey, 80%);
			}

			span.title {
				display:block;
				width:70%;
			}

			span.desc {
				color:#2F4F4F;
				display:inline-block;
				width: 55%;
					@include type(12,14,16,18,1.3, $sans);
			}

			span.price {
				float:right;
				display:block;
				// @include prop(margin-top, -25px, -35px, -40px, -50px)
			}

			span.empty {
				display:inline;
			}
		}
	}


	div.listing > ul:not(:first-child) {
  		display: none;
  		list-style-type: none;
  	}
}