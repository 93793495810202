.intro {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	& > div {
		flex: 1;
		text-align: center;
		@include prop(flex-basis, 100%, 100%, 0%, 0%);
		h1 {
			@include type(40, 50, 60, 72, 1, $serif);
			@include prop(padding, 0 0 $vert/2 0, 0 40px, 0 24%, 0 24%);
			@include prop(margin-top, 20px, 20px, -30px, -30px);
			@include prop(margin-bottom, 0, 20px, 0, 0);
		}
		&.image {
			flex: 2;
			@include prop(flex-basis, 100%, 100%, 0%, 0%);
		}

	}
}