.contact {
	display: flex;
	flex-flow: row wrap;
	@include prop(margin-top, $vert*.7, $vert*1.25, $vert*1.5, $vert*1.5);
	@include prop(margin-bottom, $vert*.7, $vert*1.25, $vert*1.5, $vert*1.5);
	.title {
		flex: 1;
		@include prop(margin-right, 0, 0, $vert, $vert);
		@include prop(margin-bottom, $vert/2, $vert, 0, 0);
		h3 {
			@include type(26,36,36,36,1.3,$serif);
			@include prop(padding-right, 0, 0, 20%, 20%);
		}
	}
	.info {
		flex: 1;
		display: flex;
		flex-flow: row wrap;
	}
	li {
		margin-bottom: $vert/6;
	}
	.details {
		flex: 1;
		margin-right: $vert;
		@include prop(margin-right, 0, $vert, $vert, $vert);
	}
	.hours {
		@include prop(flex, 1, 1, 2, 2);
	}
	.details, .hours {
		@include type(16, 16, 16, 20, 1.4, $sans);
	}
	.title, .info {
		@include prop(flex-basis, 100%, 100%, 0%, 0%);
	}
}