.blog {

  .entry {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding: $vert 10vh;

    @include phone {
      margin-top:-50px;
      padding:30px 20px;
    }

    .title {
      @include type(24,38,50,60,1.2,$serif);
      margin-top:30px;
      text-align: center;  
    }
    
    .date {
      text-align: center;
      @include type(14,18,18,24,1.2,$serif);
      line-height: 22px;
      margin-top:10px;
    }
   
    img.hero {
      display: none;
      max-width: 100%;

      @include phone {
        display:block;
        margin: 0 auto;
      }

      @include tablet {
        display:block;
        margin: 0 auto;
      }
    }
   
  }

  .content {    
    @include prop(padding-top, 0px, 0px,70px,70px);
    padding: 0 20px;
    @include prop(max-width, 100%, 100%, 60%, 50%);

    p {
      @include type(16, 16, 16, 24, 1.4, $sans);
      padding-top:20px;
      a {
        border-bottom: 1px solid #bdbdbd;
        transition: all ease .3s;
        &:hover {
          border-bottom: 1px solid #000000;
        }
        strong {
          font-weight:400;
        }
      }
    }
  }
}
