.three {
	display: flex;
	flex-flow: row wrap;
	@include prop(margin-top, 0, 0, 0, 0);
	@include prop(margin-bottom, 0, $vert, $vert*1.5, $vert*2);
	& > div {
		text-align: center;
		flex: 1;
		overflow: hidden;
		@include prop(margin-right, 0, 0, $vert, $vert);
		@include prop(margin-bottom, $vert/2, $vert/1.5, 0, 0);
		@include phone {
			flex-basis: 100%;
		}
		@include tablet {
			flex-basis: 100%;
			& > a { 
				display: flex;
				flex-flow: row wrap;
				align-items: center;
				background: lighten($grey, 83%);
				img {
					object-fit: contain;
					// flex-basis: 50%;
				}
				h4 {
					padding: 0 $vert/2;
				}
			}
			img, .info { 
				flex: 1;
				flex-basis: 50%; 
				overflow: hidden;

			}
			&:first-child, &:last-child {
				a {
					// background: red;
					img { order: 2; }
					.info { order: 1; }
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		&:last-child {
			margin-right: 0;
		}
		a {
			h4 {
				@include type(24,30,30,40, 1.15, $serif);
				@include prop(margin, $vert/3 0, $vert/3 0, $vert/3 0, $vert/3 0);
			}
			span {
				@include type(18,18,18,20, 1.15, $sans);
			}
			.category {
				display: inline-block;
				@include prop(margin-top, $vert/3.5, $vert/3.5, $vert/3.5, $vert/3.5);
				text-transform: capitalize;
			}

			.blog-title {
				@include prop(padding, 0 10px,0 10px,0 15px,0 10px);
			}
		}
	}
}