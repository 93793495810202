.entry {

	.entry-title {
		margin-top:50px;
		margin-bottom:50px;
	}

	.entry-image {
		display: block;
    	text-align: center;
    		.hero {
    			max-width:60%;
    			// max-height:1200px;
    			// margin-left:-30%;

    			// padding-left: 20vw;
    			// padding-right:20vw;
    			@include phone {
    				max-width:100%;
    			}
    			@include tablet {
    				max-width:60%;
    			}
    		}

	}

	.entry-content {
		text-align:center;
		
		.entry-block {
			display: inline-block;
			text-align:left;
			margin-left:15vw;
			margin-right:15vw;
			@include tablet {
				margin-left:15vw;
				margin-right:15vw;
			}
			p:first-child {
				padding-top:50px;
			}
			p:last-child {
				padding-bottom:120px;
			}

			p {
				margin-top:20px;
				@include type(16, 16, 16, 24, 1.4, $sans);

				a {
					border-bottom: 1px solid #bdbdbd;
                    transition: all ease .3s;
                    &:hover {
                      border-bottom: 1px solid #000000;
                    }
					strong {
						font-weight:400;
					}
				}
			}

			ul {
				
				li {
					list-style-type: square;
					margin-left:50px;
					@include type(16,16,16,24,1.4, $sans);
				}

				li:first-child {
					margin-top:20px;
				}
			}
		}
	}
	.content {
		ul {
			margin-top: 20px;
			margin-bottom: 15px;
		}
		li {
			@include type(16, 16, 16, 24, 1.4, $sans);
			list-style-type: circle;
		}
	}
}