
.contents { 
	overflow: hidden; 
	padding-top: 184px; 
	opacity: 1;
	transition: all $cubic $timing;
	&.out {
		opacity: 0;
		transform: translateY(-10px);
	}

	@include phone {
		padding-top: 135px;
	}

	@include tablet {
		padding-top: 150px;
	}
}

body {
	transition: background $cubic $timing;
	@include type(13,15,20,24,1.2,$serif);
}

.full {
	@include prop(margin, $vert/2, 0 $vert, 0 $vert*1.5, 0 $vert*2);
  
	@include phone {
		
		margin:5px;
	}
}

section, div {
  
  img { 
  		max-width: 100%;
      height: auto;

  		@include phone {
  			width:90%;
  		}	
  
  }
}
