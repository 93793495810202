footer {
	@include prop(padding, $vert/2 0 0 0, $vert 0, $vert 0, $vert 0);
	background: $grey;
	position: relative;
	z-index:2;
	li, a {
		@include type(16,16,16,18,1.3, $sans);
		color: white;
		text-align: left;

	}
	.three {
		margin-top: 0;
		margin-bottom: 0;
		div:nth-child(2){
			display: flex;
			flex-flow: row wrap;

			& > div {
				flex: 2;
				&:last-child {
					flex: 1;
				}
			}
		}

		.details, div:nth-child(2) {
			@include tablet {
				flex-basis: 50%;
			}
		}

		.email p, {
			@include phone {
				// color:red;
				margin: 0 0 0 20px;
			}
		}

		.social li {
			@include phone {
				margin: 0 0 0 20px;
				// color:blue;
			}
		}

		.hours, .details {
			li {
				margin-bottom: $vert/3;
			}
		}
		.hours, .social {
			@include phone {
				flex: 1 !important;
			}
		}
		.details ul {
			@include phone {
				display: flex;
				flex-flow: row wrap;
				li {
					flex: 1;
				}
			}
		}
		.credit {
			li {
				@include prop(text-align, left, left, right, right);
			}
			a {
				opacity: .4;
			}
		}
		.details, div:nth-child(2) {
			// @include prop(flex, 1, 1, 1, 1);
			// @include 
		}
	}
}
