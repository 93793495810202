.standfirst {
	text-align: center;
	align-items: center;
	justify-content: center;
	@include prop(margin-bottom, $vert*.7, $vert, $vert, $vert);
	max-width: 50%;
	// @include prop(padding, 0 10px, 0 70px, 0 13%, 0 20%);
	// @include prop(max-width, 60%,60%,50%,50%);

	h2 {
		@include type(28,42,48,50,1.1,$serif);
		letter-spacing: -1px;
		// @include prop(color,red,green,blue,yellow);
	}

	.contents & {
		max-width: 100%;
		padding: 10vh 10vw;
	}
}