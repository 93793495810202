
.pagination {
	
	margin: 0 auto;
	display: block;
	text-align: Center;
	padding-bottom: 60px;

	a {
		margin: 0 50px;
	}

	&.overview {
		width: 100%;
		margin: 0;
	}

}