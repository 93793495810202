.newsletter {
	@include prop(padding, $vert/1.25 0 $vert, $vert 0, $vert*1.25 0, $vert*1.4 0);
	background: $lblue;
	.three {
		margin-top: 0;
		margin-bottom: 0;
		align-items: center;
		div:first-child {
			@include prop(text-align, center, center, left, left);
		}
		div:last-child {
			@include prop(text-align, center, center, right, right);
			@include phone {
				margin-bottom: 0;
			}
		}
		div:nth-child(2) {
			@include prop(padding, 0, 0, 0 40px, 40px);
			@include tablet {
				text-align: left;
			}
		}
		div:nth-child(2), div:last-child {
			@include phone {
				padding: 0 $vert /2;
			}
			@include tablet {
				margin-bottom: 0;
				flex-basis: 50%;
				padding: 0 $vert/3;
			}
		}
		h3 {
			@include type(32,32,32,36,1.2, $serif);
		}
		p {
			@include type(16, 16, 16, 20, 1.2, $sans);
		}
		input[type="text"], input[type="email"], input[type="submit"] {
			outline: none;
			padding: 5px 0;
			border: none;
			@include type(18,18,18,18,1.3,$sans);
			background: transparent;
			border-bottom: 1px solid $grey;
			width:70%;
			float: left;
			border-radius: 0px;
		}
		input[type="submit"]  {
			margin-right: 0;
			width: 30%;
			float: left;
			transition: all $cubic .2s;
			&:hover {
				background: black;
				color: white;
				cursor: alias;
			}
		}
	}
	.response {
		padding: 15px 20px !important;
		text-align: left !important;
		// background: white;
		@include type(16, 16, 16, 20, 1.2, $sans);
		display: inline-block;
		width: 100%;
	}
}