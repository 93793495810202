
.two {
	display: flex;
	flex-flow: row wrap;
	@include prop(margin-top, $vert/2, $vert, $vert*1.5, $vert*2);
	@include prop(margin-bottom, 0, $vert, $vert*1.5, $vert*2);	

	.image {
		@include phone {text-align:center;}
	}

	& > div {
		flex: 1;
		@include prop(margin-right, 0, $vert, $vert, $vert);
		@include prop(margin-bottom, $vert/2, 0, 0, 0);
		@include prop(flex-basis, 100%, 20%, 0%, 40%); //changed 0 to 40%
		&:last-child {
			margin-right: 0;
		}
		h2 {
			@include prop(padding-right, 0, 0, 10%, 10%); 
			@include type(28,32,32,40, 1.2, $serif);
			@include prop(margin-bottom, $vert/3, $vert/1.5, $vert/1.5, $vert/1.5);
			@include phone{text-align:center;}
		}
		p {
			@include prop(padding-right, 0, 0, 30%, 30%); 
			@include type(16, 16, 16, 20, 1.4, $sans);
			@include prop(margin-bottom, $vert/3, $vert/3, $vert/3, $vert/3);
			@include phone{margin: 0 10px;}
		}
		.push {
			@include prop(margin-bottom, $vert/3, $vert*1.5, $vert*1, $vert*1);

		}
	}

	& > .info {
		p:last-child {
			@include prop(margin-bottom, 0, $vert/3, $vert/3, $vert/3);
		}
	}
}