
header {
	position: fixed;
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	z-index: 2;
	background: $white;
    transition: all ease 0.5s;
	top: 0;
    max-height: 500px;

	&.locked {
	    max-height: 90px;

	    @include phone {
	    	max-height: 210px;
	    }
	}

	&.ghost {
		background-color: $white;
	  	padding-left: 90px;
	  	padding-right: 90px;
	  	margin-left:0;
	  	margin-right:0;

		  	@include phone {
		  		padding: 2px;
		  	}
	}

	& > div {
		text-align: center;
		@include prop(margin, $vert/4 0, $vert/1.5 0, $vert 0, $vert 0);
		@include prop(flex-basis, 50%, 40%, 40%, 40%);
		overflow: hidden;
		transition: all cubic-bezier(.2, .87, .69, .96).5s;
		img {
			max-width: 100%;
		}
		li a {
			position: relative;
			@include prop(margin, 10px, 10px $vert/2.5, 20px $vert/1.5, 20px $vert*1.25);
			@include type(18,18,20,24,1.2,$serif);

			&:after {
				content: ' ';
				position: absolute;
				display: block;
				width: 4px;
				height: 4px;
				background: black;
				left: 50%;
				bottom: 0px;
				border-radius: 3px;
				transform: translateY(15px) translateX(-50%) rotateY(90deg);
				transition: all $cubic .2s;				
			}
		}

		a.active:after, a:hover:after {
			background: black;
				transform: translateY(15px) translateX(-50%);
		}
		&:first-child {
			@include prop(order, 1, 2, 2, 2);
			@include prop(flex-basis, 100%, 20%, 20%, 20%);
		}
		&:nth-child(2) {
			order: 1;
			@include prop(text-align, right, center, center, center);
		}
		&:last-child {
			order: 3;
			@include prop(text-align, left, center, center, center);
		}
	}
	li {
		display: inline-block;
		overflow: visible;
		padding: $vert/3 0;

		@include phone {
			padding:0;
		}
	}
	a.logo{
		display: inline-block;
		img {
			width: 100%;
			height: auto;
		}
	}

	@include phone {
		position: absolute;
		margin-bottom: 0px;
	}
}