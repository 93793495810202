.slider {
    position: relative;
    margin-bottom: $vert;
    text-align: center;
	@include prop(margin, 0, 0, 0 $vert*1.5, $vert/2 $vert*2);

	img{width:100%;}
	h1 {
		position: absolute;
		color: white;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		z-index: 1;
		pointer-events: none;
		@include type(34,48,67,92,1.2,$serif);
		@include phone {
			line-height: 1.1em;
		}
	}

	.carousel {
		// height: inherit;
		// position: relative;
		// overflow: hidden;
		.slick-prev, .slick-next {
			outline: none;
			display: block;
			position: absolute;
			top: 50%;
			height: 16px;
			width: 16px;
			z-index: 9999;
			border: none;
			text-indent: -9999px;
			background: $grey;
		}
		.slick-prev {
			left: 0px;
			@include prop(margin-left, -$vert, -$vert, -$vert*.75, -$vert);
			transform: translateY(-50%) rotate(-45deg);
			&:after {
				content: ' ';
				display: block;
				position: absolute;
				right: -2px;
				bottom: -2px;
				width: 17px;
				height: 17px;
				background:white;
				transition: all $cubic .2s;
			}
			&:hover {
				cursor: pointer;
				&:after {
					width: 14px;
					height: 14px;
				}
			}
		}
		.slick-next {
			right: 0px;
			@include prop(margin-right, -$vert, -$vert, -$vert*.75, -$vert);
			transform: translateY(-50%) rotate(45deg);
			&:after {
				content: ' ';
				position: absolute;
				left: -2px;
				bottom: -2px;
				width: 17px;
				height: 17px;
				background:white;
				transition: all $cubic .2s;
			}
			&:hover {
				cursor: pointer;
				&:after {
					width: 14px;
					height: 14px;
				}
			}
		}
	}
}