@font-face {
  font-family: 'Dala Floda Web';
  src: url('../fonts/DalaFloda-RomanNo2-Web.eot');
  src: url('../fonts/DalaFloda-RomanNo2-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/DalaFloda-RomanNo2-Web.woff2') format('woff2'),
       url('../fonts/DalaFloda-RomanNo2-Web.woff') format('woff');
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}

.DalaFloda-RomanNo2-Web {
  font-family: 'Dala Floda Web';
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}


/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: local('Karla'), local('Karla-Regular'), url(https://fonts.gstatic.com/s/karla/v5/S1bXQ0LrY7AzefpgNae9sYDGDUGfDkXyfkzVDelzfFk.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: local('Karla'), local('Karla-Regular'), url(https://fonts.gstatic.com/s/karla/v5/JaMH4jmmzP070-OYo03anaCWcynf_cDxXwCLxiixG1c.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}