* { box-sizing: border-box; -webkit-box-sizing: border-box; margin: 0; padding: 0; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

// Breakpoint Mixins
@mixin phone { @media (max-width: 480px) { @content; } }
@mixin tablet { @media only screen and (min-width: 481px) and (max-width: 1000px) { @content; } }
@mixin desk { @media only screen and (min-width: 1001px) and (max-width: 1599px) { @content; } }
@mixin super { @media only screen and (min-width: 1600px) { @content; } }


// Type Mixin
@mixin type($phone, $tablet, $desk, $super, $lineheightratio, $font) {
  @include super {
    // color: blue;
    font: $super+px / ($super*$lineheightratio)+px $font;
  }
  @include desk {
    // color: green;
    font: $desk+px / ($desk*$lineheightratio)+px $font;
  }
  @include tablet {
    // color: maroon;
    font: $tablet+px / ($tablet*$lineheightratio)+px $font;
  }
  @include phone {
    // color: red;
    font: ($phone)+px / ($phone*$lineheightratio)+px $font;
  }
}

// Prefix
@mixin prefix($property, $val) {
  -webkit-#{$property}: #{$val};
  #{$property}: #{$val};
}
// Dynamic media query mixin
@mixin prop($property, $phoneval, $tabletval, $deskval, $superval) {
  @include phone {
    #{$property}: #{$phoneval};
  }
  @include tablet {
    #{$property}: #{$tabletval};
  }
  @include desk {
    #{$property}: #{$deskval};
  }
  @include super {
    #{$property}: #{$superval};
  }
}

// Variables
$cubic: cubic-bezier(.71,.04,.32,.95);
$timing: .5s;
$sans: 'Karla', Arial, Helvetica, sans-serif;
$serif: 'Dala Floda Web', Georgia, serif;
$vert: 60px;
$grey: #231f20;
$lblue: #d7e7f4;
$white: #fff;

$third: calc(100% / 3);

// General
body { overflow-y: scroll; overflow-x: hidden;
  &::after {
      transition: all $cubic .5s;
      content: '';
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: white;
      pointer-events: none;
      opacity: 0;
    }  
  &.hide {
    &::after {
      opacity: 1;
    }
  }
}
h1,h2,h3,h4,h5,h6 { font-weight: normal; }
li { list-style-type: none; }
a { text-decoration: none; color: black; }
::selection { background: #fff9a9; color: $grey; outline: none; }
::-webkit-selection { background: #fff9a9; color: $grey; outline: none; }
::-webkit-input-placeholder { color: $grey; }
:-moz-placeholder { color: $grey; }
::-moz-placeholder { color: $grey; }
:-ms-input-placeholder { color: $grey; }

// Utilities
.remove { display: none; }
.hide { opacity: 0; pointer-events: none; }

