* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  overflow-y: scroll;
  overflow-x: hidden; }
  body::after {
    transition: all cubic-bezier(0.71, 0.04, 0.32, 0.95) 0.5s;
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: white;
    pointer-events: none;
    opacity: 0; }
  body.hide::after {
    opacity: 1; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

li {
  list-style-type: none; }

a {
  text-decoration: none;
  color: black; }

::selection {
  background: #fff9a9;
  color: #231f20;
  outline: none; }

::-webkit-selection {
  background: #fff9a9;
  color: #231f20;
  outline: none; }

::-webkit-input-placeholder {
  color: #231f20; }

:-moz-placeholder {
  color: #231f20; }

::-moz-placeholder {
  color: #231f20; }

:-ms-input-placeholder {
  color: #231f20; }

.remove {
  display: none; }

.hide {
  opacity: 0;
  pointer-events: none; }

.contents {
  overflow: hidden;
  padding-top: 184px;
  opacity: 1;
  transition: all cubic-bezier(0.71, 0.04, 0.32, 0.95) 0.5s; }
  .contents.out {
    opacity: 0;
    transform: translateY(-10px); }
  @media (max-width: 480px) {
    .contents {
      padding-top: 135px; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .contents {
      padding-top: 150px; } }

body {
  transition: background cubic-bezier(0.71, 0.04, 0.32, 0.95) 0.5s; }
  @media only screen and (min-width: 1600px) {
    body {
      font: 24px/28.8px "Dala Floda Web", Georgia, serif; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    body {
      font: 20px/24px "Dala Floda Web", Georgia, serif; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    body {
      font: 15px/18px "Dala Floda Web", Georgia, serif; } }
  @media (max-width: 480px) {
    body {
      font: 13px/15.6px "Dala Floda Web", Georgia, serif; } }

@media (max-width: 480px) {
  .full {
    margin: 30px; } }

@media only screen and (min-width: 481px) and (max-width: 1000px) {
  .full {
    margin: 0 60px; } }

@media only screen and (min-width: 1001px) and (max-width: 1599px) {
  .full {
    margin: 0 90px; } }

@media only screen and (min-width: 1600px) {
  .full {
    margin: 0 120px; } }

@media (max-width: 480px) {
  .full {
    margin: 5px; } }

section img, div img {
  max-width: 100%;
  height: auto; }
  @media (max-width: 480px) {
    section img, div img {
      width: 90%; } }

header {
  position: fixed;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  z-index: 2;
  background: #fff;
  transition: all ease 0.5s;
  top: 0;
  max-height: 500px; }
  header.locked {
    max-height: 90px; }
    @media (max-width: 480px) {
      header.locked {
        max-height: 210px; } }
  header.ghost {
    background-color: #fff;
    padding-left: 90px;
    padding-right: 90px;
    margin-left: 0;
    margin-right: 0; }
    @media (max-width: 480px) {
      header.ghost {
        padding: 2px; } }
  header > div {
    text-align: center;
    overflow: hidden;
    transition: all cubic-bezier(0.2, 0.87, 0.69, 0.96) 0.5s; }
    @media (max-width: 480px) {
      header > div {
        margin: 15px 0; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      header > div {
        margin: 40px 0; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      header > div {
        margin: 60px 0; } }
    @media only screen and (min-width: 1600px) {
      header > div {
        margin: 60px 0; } }
    @media (max-width: 480px) {
      header > div {
        flex-basis: 50%; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      header > div {
        flex-basis: 40%; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      header > div {
        flex-basis: 40%; } }
    @media only screen and (min-width: 1600px) {
      header > div {
        flex-basis: 40%; } }
    header > div img {
      max-width: 100%; }
    header > div li a {
      position: relative; }
      @media (max-width: 480px) {
        header > div li a {
          margin: 10px; } }
      @media only screen and (min-width: 481px) and (max-width: 1000px) {
        header > div li a {
          margin: 10px 24px; } }
      @media only screen and (min-width: 1001px) and (max-width: 1599px) {
        header > div li a {
          margin: 20px 40px; } }
      @media only screen and (min-width: 1600px) {
        header > div li a {
          margin: 20px 75px; } }
      @media only screen and (min-width: 1600px) {
        header > div li a {
          font: 24px/28.8px "Dala Floda Web", Georgia, serif; } }
      @media only screen and (min-width: 1001px) and (max-width: 1599px) {
        header > div li a {
          font: 20px/24px "Dala Floda Web", Georgia, serif; } }
      @media only screen and (min-width: 481px) and (max-width: 1000px) {
        header > div li a {
          font: 18px/21.6px "Dala Floda Web", Georgia, serif; } }
      @media (max-width: 480px) {
        header > div li a {
          font: 18px/21.6px "Dala Floda Web", Georgia, serif; } }
      header > div li a:after {
        content: ' ';
        position: absolute;
        display: block;
        width: 4px;
        height: 4px;
        background: black;
        left: 50%;
        bottom: 0px;
        border-radius: 3px;
        transform: translateY(15px) translateX(-50%) rotateY(90deg);
        transition: all cubic-bezier(0.71, 0.04, 0.32, 0.95) 0.2s; }
    header > div a.active:after, header > div a:hover:after {
      background: black;
      transform: translateY(15px) translateX(-50%); }
    @media (max-width: 480px) {
      header > div:first-child {
        order: 1; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      header > div:first-child {
        order: 2; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      header > div:first-child {
        order: 2; } }
    @media only screen and (min-width: 1600px) {
      header > div:first-child {
        order: 2; } }
    @media (max-width: 480px) {
      header > div:first-child {
        flex-basis: 100%; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      header > div:first-child {
        flex-basis: 20%; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      header > div:first-child {
        flex-basis: 20%; } }
    @media only screen and (min-width: 1600px) {
      header > div:first-child {
        flex-basis: 20%; } }
    header > div:nth-child(2) {
      order: 1; }
      @media (max-width: 480px) {
        header > div:nth-child(2) {
          text-align: right; } }
      @media only screen and (min-width: 481px) and (max-width: 1000px) {
        header > div:nth-child(2) {
          text-align: center; } }
      @media only screen and (min-width: 1001px) and (max-width: 1599px) {
        header > div:nth-child(2) {
          text-align: center; } }
      @media only screen and (min-width: 1600px) {
        header > div:nth-child(2) {
          text-align: center; } }
    header > div:last-child {
      order: 3; }
      @media (max-width: 480px) {
        header > div:last-child {
          text-align: left; } }
      @media only screen and (min-width: 481px) and (max-width: 1000px) {
        header > div:last-child {
          text-align: center; } }
      @media only screen and (min-width: 1001px) and (max-width: 1599px) {
        header > div:last-child {
          text-align: center; } }
      @media only screen and (min-width: 1600px) {
        header > div:last-child {
          text-align: center; } }
  header li {
    display: inline-block;
    overflow: visible;
    padding: 20px 0; }
    @media (max-width: 480px) {
      header li {
        padding: 0; } }
  header a.logo {
    display: inline-block; }
    header a.logo img {
      width: 100%;
      height: auto; }
  @media (max-width: 480px) {
    header {
      position: absolute;
      margin-bottom: 0px; } }

.slider {
  position: relative;
  margin-bottom: 60px;
  text-align: center; }
  @media (max-width: 480px) {
    .slider {
      margin: 0; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .slider {
      margin: 0; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .slider {
      margin: 0 90px; } }
  @media only screen and (min-width: 1600px) {
    .slider {
      margin: 30px 120px; } }
  .slider img {
    width: 100%; }
  .slider h1 {
    position: absolute;
    color: white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 1;
    pointer-events: none; }
    @media only screen and (min-width: 1600px) {
      .slider h1 {
        font: 92px/110.4px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .slider h1 {
        font: 67px/80.4px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .slider h1 {
        font: 48px/57.6px "Dala Floda Web", Georgia, serif; } }
    @media (max-width: 480px) {
      .slider h1 {
        font: 34px/40.8px "Dala Floda Web", Georgia, serif; } }
    @media (max-width: 480px) {
      .slider h1 {
        line-height: 1.1em; } }
  .slider .carousel .slick-prev, .slider .carousel .slick-next {
    outline: none;
    display: block;
    position: absolute;
    top: 50%;
    height: 16px;
    width: 16px;
    z-index: 9999;
    border: none;
    text-indent: -9999px;
    background: #231f20; }
  .slider .carousel .slick-prev {
    left: 0px;
    transform: translateY(-50%) rotate(-45deg); }
    @media (max-width: 480px) {
      .slider .carousel .slick-prev {
        margin-left: -60px; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .slider .carousel .slick-prev {
        margin-left: -60px; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .slider .carousel .slick-prev {
        margin-left: -45px; } }
    @media only screen and (min-width: 1600px) {
      .slider .carousel .slick-prev {
        margin-left: -60px; } }
    .slider .carousel .slick-prev:after {
      content: ' ';
      display: block;
      position: absolute;
      right: -2px;
      bottom: -2px;
      width: 17px;
      height: 17px;
      background: white;
      transition: all cubic-bezier(0.71, 0.04, 0.32, 0.95) 0.2s; }
    .slider .carousel .slick-prev:hover {
      cursor: pointer; }
      .slider .carousel .slick-prev:hover:after {
        width: 14px;
        height: 14px; }
  .slider .carousel .slick-next {
    right: 0px;
    transform: translateY(-50%) rotate(45deg); }
    @media (max-width: 480px) {
      .slider .carousel .slick-next {
        margin-right: -60px; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .slider .carousel .slick-next {
        margin-right: -60px; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .slider .carousel .slick-next {
        margin-right: -45px; } }
    @media only screen and (min-width: 1600px) {
      .slider .carousel .slick-next {
        margin-right: -60px; } }
    .slider .carousel .slick-next:after {
      content: ' ';
      position: absolute;
      left: -2px;
      bottom: -2px;
      width: 17px;
      height: 17px;
      background: white;
      transition: all cubic-bezier(0.71, 0.04, 0.32, 0.95) 0.2s; }
    .slider .carousel .slick-next:hover {
      cursor: pointer; }
      .slider .carousel .slick-next:hover:after {
        width: 14px;
        height: 14px; }

.standfirst {
  text-align: center;
  align-items: center;
  justify-content: center;
  max-width: 50%; }
  @media (max-width: 480px) {
    .standfirst {
      margin-bottom: 42px; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .standfirst {
      margin-bottom: 60px; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .standfirst {
      margin-bottom: 60px; } }
  @media only screen and (min-width: 1600px) {
    .standfirst {
      margin-bottom: 60px; } }
  .standfirst h2 {
    letter-spacing: -1px; }
    @media only screen and (min-width: 1600px) {
      .standfirst h2 {
        font: 50px/55px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .standfirst h2 {
        font: 48px/52.8px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .standfirst h2 {
        font: 42px/46.2px "Dala Floda Web", Georgia, serif; } }
    @media (max-width: 480px) {
      .standfirst h2 {
        font: 28px/30.8px "Dala Floda Web", Georgia, serif; } }
  .contents .standfirst {
    max-width: 100%;
    padding: 10vh 10vw; }

.map #map {
  flex: 1;
  flex-basis: 100%; }
  @media (max-width: 480px) {
    .map #map {
      min-height: 330px; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .map #map {
      min-height: 50vh; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .map #map {
      min-height: 60vh; } }
  @media only screen and (min-width: 1600px) {
    .map #map {
      min-height: 60vh; } }

.contact {
  display: flex;
  flex-flow: row wrap; }
  @media (max-width: 480px) {
    .contact {
      margin-top: 42px; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .contact {
      margin-top: 75px; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .contact {
      margin-top: 90px; } }
  @media only screen and (min-width: 1600px) {
    .contact {
      margin-top: 90px; } }
  @media (max-width: 480px) {
    .contact {
      margin-bottom: 42px; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .contact {
      margin-bottom: 75px; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .contact {
      margin-bottom: 90px; } }
  @media only screen and (min-width: 1600px) {
    .contact {
      margin-bottom: 90px; } }
  .contact .title {
    flex: 1; }
    @media (max-width: 480px) {
      .contact .title {
        margin-right: 0; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .contact .title {
        margin-right: 0; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .contact .title {
        margin-right: 60px; } }
    @media only screen and (min-width: 1600px) {
      .contact .title {
        margin-right: 60px; } }
    @media (max-width: 480px) {
      .contact .title {
        margin-bottom: 30px; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .contact .title {
        margin-bottom: 60px; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .contact .title {
        margin-bottom: 0; } }
    @media only screen and (min-width: 1600px) {
      .contact .title {
        margin-bottom: 0; } }
    @media only screen and (min-width: 1600px) {
      .contact .title h3 {
        font: 36px/46.8px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .contact .title h3 {
        font: 36px/46.8px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .contact .title h3 {
        font: 36px/46.8px "Dala Floda Web", Georgia, serif; } }
    @media (max-width: 480px) {
      .contact .title h3 {
        font: 26px/33.8px "Dala Floda Web", Georgia, serif; } }
    @media (max-width: 480px) {
      .contact .title h3 {
        padding-right: 0; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .contact .title h3 {
        padding-right: 0; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .contact .title h3 {
        padding-right: 20%; } }
    @media only screen and (min-width: 1600px) {
      .contact .title h3 {
        padding-right: 20%; } }
  .contact .info {
    flex: 1;
    display: flex;
    flex-flow: row wrap; }
  .contact li {
    margin-bottom: 10px; }
  .contact .details {
    flex: 1;
    margin-right: 60px; }
    @media (max-width: 480px) {
      .contact .details {
        margin-right: 0; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .contact .details {
        margin-right: 60px; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .contact .details {
        margin-right: 60px; } }
    @media only screen and (min-width: 1600px) {
      .contact .details {
        margin-right: 60px; } }
  @media (max-width: 480px) {
    .contact .hours {
      flex: 1; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .contact .hours {
      flex: 1; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .contact .hours {
      flex: 2; } }
  @media only screen and (min-width: 1600px) {
    .contact .hours {
      flex: 2; } }
  @media only screen and (min-width: 1600px) {
    .contact .details, .contact .hours {
      font: 20px/28px "Karla", Arial, Helvetica, sans-serif; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .contact .details, .contact .hours {
      font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .contact .details, .contact .hours {
      font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
  @media (max-width: 480px) {
    .contact .details, .contact .hours {
      font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
  @media (max-width: 480px) {
    .contact .title, .contact .info {
      flex-basis: 100%; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .contact .title, .contact .info {
      flex-basis: 100%; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .contact .title, .contact .info {
      flex-basis: 0%; } }
  @media only screen and (min-width: 1600px) {
    .contact .title, .contact .info {
      flex-basis: 0%; } }

.three {
  display: flex;
  flex-flow: row wrap; }
  @media (max-width: 480px) {
    .three {
      margin-top: 0; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .three {
      margin-top: 0; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .three {
      margin-top: 0; } }
  @media only screen and (min-width: 1600px) {
    .three {
      margin-top: 0; } }
  @media (max-width: 480px) {
    .three {
      margin-bottom: 0; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .three {
      margin-bottom: 60px; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .three {
      margin-bottom: 90px; } }
  @media only screen and (min-width: 1600px) {
    .three {
      margin-bottom: 120px; } }
  .three > div {
    text-align: center;
    flex: 1;
    overflow: hidden; }
    @media (max-width: 480px) {
      .three > div {
        margin-right: 0; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .three > div {
        margin-right: 0; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .three > div {
        margin-right: 60px; } }
    @media only screen and (min-width: 1600px) {
      .three > div {
        margin-right: 60px; } }
    @media (max-width: 480px) {
      .three > div {
        margin-bottom: 30px; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .three > div {
        margin-bottom: 40px; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .three > div {
        margin-bottom: 0; } }
    @media only screen and (min-width: 1600px) {
      .three > div {
        margin-bottom: 0; } }
    @media (max-width: 480px) {
      .three > div {
        flex-basis: 100%; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .three > div {
        flex-basis: 100%; }
        .three > div > a {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          background: #f5f4f4; }
          .three > div > a img {
            object-fit: contain; }
          .three > div > a h4 {
            padding: 0 30px; }
        .three > div img, .three > div .info {
          flex: 1;
          flex-basis: 50%;
          overflow: hidden; }
        .three > div:first-child a img, .three > div:last-child a img {
          order: 2; }
        .three > div:first-child a .info, .three > div:last-child a .info {
          order: 1; }
        .three > div:last-child {
          margin-bottom: 0; } }
    .three > div:last-child {
      margin-right: 0; }
    @media only screen and (min-width: 1600px) {
      .three > div a h4 {
        font: 40px/46px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .three > div a h4 {
        font: 30px/34.5px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .three > div a h4 {
        font: 30px/34.5px "Dala Floda Web", Georgia, serif; } }
    @media (max-width: 480px) {
      .three > div a h4 {
        font: 24px/27.6px "Dala Floda Web", Georgia, serif; } }
    @media (max-width: 480px) {
      .three > div a h4 {
        margin: 20px 0; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .three > div a h4 {
        margin: 20px 0; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .three > div a h4 {
        margin: 20px 0; } }
    @media only screen and (min-width: 1600px) {
      .three > div a h4 {
        margin: 20px 0; } }
    @media only screen and (min-width: 1600px) {
      .three > div a span {
        font: 20px/23px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .three > div a span {
        font: 18px/20.7px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .three > div a span {
        font: 18px/20.7px "Karla", Arial, Helvetica, sans-serif; } }
    @media (max-width: 480px) {
      .three > div a span {
        font: 18px/20.7px "Karla", Arial, Helvetica, sans-serif; } }
    .three > div a .category {
      display: inline-block;
      text-transform: capitalize; }
      @media (max-width: 480px) {
        .three > div a .category {
          margin-top: 17.14286px; } }
      @media only screen and (min-width: 481px) and (max-width: 1000px) {
        .three > div a .category {
          margin-top: 17.14286px; } }
      @media only screen and (min-width: 1001px) and (max-width: 1599px) {
        .three > div a .category {
          margin-top: 17.14286px; } }
      @media only screen and (min-width: 1600px) {
        .three > div a .category {
          margin-top: 17.14286px; } }
    @media (max-width: 480px) {
      .three > div a .blog-title {
        padding: 0 10px; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .three > div a .blog-title {
        padding: 0 10px; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .three > div a .blog-title {
        padding: 0 15px; } }
    @media only screen and (min-width: 1600px) {
      .three > div a .blog-title {
        padding: 0 10px; } }

.featured img {
  width: 700;
  height: 500; }

.two {
  display: flex;
  flex-flow: row wrap; }
  @media (max-width: 480px) {
    .two {
      margin-top: 30px; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .two {
      margin-top: 60px; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .two {
      margin-top: 90px; } }
  @media only screen and (min-width: 1600px) {
    .two {
      margin-top: 120px; } }
  @media (max-width: 480px) {
    .two {
      margin-bottom: 0; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .two {
      margin-bottom: 60px; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .two {
      margin-bottom: 90px; } }
  @media only screen and (min-width: 1600px) {
    .two {
      margin-bottom: 120px; } }
  @media (max-width: 480px) {
    .two .image {
      text-align: center; } }
  .two > div {
    flex: 1; }
    @media (max-width: 480px) {
      .two > div {
        margin-right: 0; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .two > div {
        margin-right: 60px; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .two > div {
        margin-right: 60px; } }
    @media only screen and (min-width: 1600px) {
      .two > div {
        margin-right: 60px; } }
    @media (max-width: 480px) {
      .two > div {
        margin-bottom: 30px; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .two > div {
        margin-bottom: 0; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .two > div {
        margin-bottom: 0; } }
    @media only screen and (min-width: 1600px) {
      .two > div {
        margin-bottom: 0; } }
    @media (max-width: 480px) {
      .two > div {
        flex-basis: 100%; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .two > div {
        flex-basis: 20%; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .two > div {
        flex-basis: 0%; } }
    @media only screen and (min-width: 1600px) {
      .two > div {
        flex-basis: 40%; } }
    .two > div:last-child {
      margin-right: 0; }
    @media (max-width: 480px) {
      .two > div h2 {
        padding-right: 0; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .two > div h2 {
        padding-right: 0; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .two > div h2 {
        padding-right: 10%; } }
    @media only screen and (min-width: 1600px) {
      .two > div h2 {
        padding-right: 10%; } }
    @media only screen and (min-width: 1600px) {
      .two > div h2 {
        font: 40px/48px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .two > div h2 {
        font: 32px/38.4px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .two > div h2 {
        font: 32px/38.4px "Dala Floda Web", Georgia, serif; } }
    @media (max-width: 480px) {
      .two > div h2 {
        font: 28px/33.6px "Dala Floda Web", Georgia, serif; } }
    @media (max-width: 480px) {
      .two > div h2 {
        margin-bottom: 20px; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .two > div h2 {
        margin-bottom: 40px; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .two > div h2 {
        margin-bottom: 40px; } }
    @media only screen and (min-width: 1600px) {
      .two > div h2 {
        margin-bottom: 40px; } }
    @media (max-width: 480px) {
      .two > div h2 {
        text-align: center; } }
    @media (max-width: 480px) {
      .two > div p {
        padding-right: 0; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .two > div p {
        padding-right: 0; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .two > div p {
        padding-right: 30%; } }
    @media only screen and (min-width: 1600px) {
      .two > div p {
        padding-right: 30%; } }
    @media only screen and (min-width: 1600px) {
      .two > div p {
        font: 20px/28px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .two > div p {
        font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .two > div p {
        font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
    @media (max-width: 480px) {
      .two > div p {
        font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
    @media (max-width: 480px) {
      .two > div p {
        margin-bottom: 20px; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .two > div p {
        margin-bottom: 20px; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .two > div p {
        margin-bottom: 20px; } }
    @media only screen and (min-width: 1600px) {
      .two > div p {
        margin-bottom: 20px; } }
    @media (max-width: 480px) {
      .two > div p {
        margin: 0 10px; } }
    @media (max-width: 480px) {
      .two > div .push {
        margin-bottom: 20px; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .two > div .push {
        margin-bottom: 90px; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .two > div .push {
        margin-bottom: 60px; } }
    @media only screen and (min-width: 1600px) {
      .two > div .push {
        margin-bottom: 60px; } }
  @media (max-width: 480px) {
    .two > .info p:last-child {
      margin-bottom: 0; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .two > .info p:last-child {
      margin-bottom: 20px; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .two > .info p:last-child {
      margin-bottom: 20px; } }
  @media only screen and (min-width: 1600px) {
    .two > .info p:last-child {
      margin-bottom: 20px; } }

.intro {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .intro > div {
    flex: 1;
    text-align: center; }
    @media (max-width: 480px) {
      .intro > div {
        flex-basis: 100%; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .intro > div {
        flex-basis: 100%; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .intro > div {
        flex-basis: 0%; } }
    @media only screen and (min-width: 1600px) {
      .intro > div {
        flex-basis: 0%; } }
    @media only screen and (min-width: 1600px) {
      .intro > div h1 {
        font: 72px/72px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .intro > div h1 {
        font: 60px/60px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .intro > div h1 {
        font: 50px/50px "Dala Floda Web", Georgia, serif; } }
    @media (max-width: 480px) {
      .intro > div h1 {
        font: 40px/40px "Dala Floda Web", Georgia, serif; } }
    @media (max-width: 480px) {
      .intro > div h1 {
        padding: 0 0 30px 0; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .intro > div h1 {
        padding: 0 40px; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .intro > div h1 {
        padding: 0 24%; } }
    @media only screen and (min-width: 1600px) {
      .intro > div h1 {
        padding: 0 24%; } }
    @media (max-width: 480px) {
      .intro > div h1 {
        margin-top: 20px; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .intro > div h1 {
        margin-top: 20px; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .intro > div h1 {
        margin-top: -30px; } }
    @media only screen and (min-width: 1600px) {
      .intro > div h1 {
        margin-top: -30px; } }
    @media (max-width: 480px) {
      .intro > div h1 {
        margin-bottom: 0; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .intro > div h1 {
        margin-bottom: 20px; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .intro > div h1 {
        margin-bottom: 0; } }
    @media only screen and (min-width: 1600px) {
      .intro > div h1 {
        margin-bottom: 0; } }
    .intro > div.image {
      flex: 2; }
      @media (max-width: 480px) {
        .intro > div.image {
          flex-basis: 100%; } }
      @media only screen and (min-width: 481px) and (max-width: 1000px) {
        .intro > div.image {
          flex-basis: 100%; } }
      @media only screen and (min-width: 1001px) and (max-width: 1599px) {
        .intro > div.image {
          flex-basis: 0%; } }
      @media only screen and (min-width: 1600px) {
        .intro > div.image {
          flex-basis: 0%; } }

.newsletter {
  background: #d7e7f4; }
  @media (max-width: 480px) {
    .newsletter {
      padding: 48px 0 60px; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .newsletter {
      padding: 60px 0; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .newsletter {
      padding: 75px 0; } }
  @media only screen and (min-width: 1600px) {
    .newsletter {
      padding: 84px 0; } }
  .newsletter .three {
    margin-top: 0;
    margin-bottom: 0;
    align-items: center; }
    @media (max-width: 480px) {
      .newsletter .three div:first-child {
        text-align: center; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .newsletter .three div:first-child {
        text-align: center; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .newsletter .three div:first-child {
        text-align: left; } }
    @media only screen and (min-width: 1600px) {
      .newsletter .three div:first-child {
        text-align: left; } }
    @media (max-width: 480px) {
      .newsletter .three div:last-child {
        text-align: center; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .newsletter .three div:last-child {
        text-align: center; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .newsletter .three div:last-child {
        text-align: right; } }
    @media only screen and (min-width: 1600px) {
      .newsletter .three div:last-child {
        text-align: right; } }
    @media (max-width: 480px) {
      .newsletter .three div:last-child {
        margin-bottom: 0; } }
    @media (max-width: 480px) {
      .newsletter .three div:nth-child(2) {
        padding: 0; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .newsletter .three div:nth-child(2) {
        padding: 0; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .newsletter .three div:nth-child(2) {
        padding: 0 40px; } }
    @media only screen and (min-width: 1600px) {
      .newsletter .three div:nth-child(2) {
        padding: 40px; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .newsletter .three div:nth-child(2) {
        text-align: left; } }
    @media (max-width: 480px) {
      .newsletter .three div:nth-child(2), .newsletter .three div:last-child {
        padding: 0 30px; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .newsletter .three div:nth-child(2), .newsletter .three div:last-child {
        margin-bottom: 0;
        flex-basis: 50%;
        padding: 0 20px; } }
    @media only screen and (min-width: 1600px) {
      .newsletter .three h3 {
        font: 36px/43.2px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .newsletter .three h3 {
        font: 32px/38.4px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .newsletter .three h3 {
        font: 32px/38.4px "Dala Floda Web", Georgia, serif; } }
    @media (max-width: 480px) {
      .newsletter .three h3 {
        font: 32px/38.4px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 1600px) {
      .newsletter .three p {
        font: 20px/24px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .newsletter .three p {
        font: 16px/19.2px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .newsletter .three p {
        font: 16px/19.2px "Karla", Arial, Helvetica, sans-serif; } }
    @media (max-width: 480px) {
      .newsletter .three p {
        font: 16px/19.2px "Karla", Arial, Helvetica, sans-serif; } }
    .newsletter .three input[type="text"], .newsletter .three input[type="email"], .newsletter .three input[type="submit"] {
      outline: none;
      padding: 5px 0;
      border: none;
      background: transparent;
      border-bottom: 1px solid #231f20;
      width: 70%;
      float: left;
      border-radius: 0px; }
      @media only screen and (min-width: 1600px) {
        .newsletter .three input[type="text"], .newsletter .three input[type="email"], .newsletter .three input[type="submit"] {
          font: 18px/23.4px "Karla", Arial, Helvetica, sans-serif; } }
      @media only screen and (min-width: 1001px) and (max-width: 1599px) {
        .newsletter .three input[type="text"], .newsletter .three input[type="email"], .newsletter .three input[type="submit"] {
          font: 18px/23.4px "Karla", Arial, Helvetica, sans-serif; } }
      @media only screen and (min-width: 481px) and (max-width: 1000px) {
        .newsletter .three input[type="text"], .newsletter .three input[type="email"], .newsletter .three input[type="submit"] {
          font: 18px/23.4px "Karla", Arial, Helvetica, sans-serif; } }
      @media (max-width: 480px) {
        .newsletter .three input[type="text"], .newsletter .three input[type="email"], .newsletter .three input[type="submit"] {
          font: 18px/23.4px "Karla", Arial, Helvetica, sans-serif; } }
    .newsletter .three input[type="submit"] {
      margin-right: 0;
      width: 30%;
      float: left;
      transition: all cubic-bezier(0.71, 0.04, 0.32, 0.95) 0.2s; }
      .newsletter .three input[type="submit"]:hover {
        background: black;
        color: white;
        cursor: alias; }
  .newsletter .response {
    padding: 15px 20px !important;
    text-align: left !important;
    display: inline-block;
    width: 100%; }
    @media only screen and (min-width: 1600px) {
      .newsletter .response {
        font: 20px/24px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .newsletter .response {
        font: 16px/19.2px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .newsletter .response {
        font: 16px/19.2px "Karla", Arial, Helvetica, sans-serif; } }
    @media (max-width: 480px) {
      .newsletter .response {
        font: 16px/19.2px "Karla", Arial, Helvetica, sans-serif; } }

.menunav {
  background: #f5f4f4; }
  @media (max-width: 480px) {
    .menunav {
      padding: 5px 0 1px; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .menunav {
      padding: 20px 0; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .menunav {
      padding: 45px 0; } }
  @media only screen and (min-width: 1600px) {
    .menunav {
      padding: 45px 0; } }
  .menunav > div.full {
    text-align: center; }
    @media (max-width: 480px) {
      .menunav > div.full {
        margin: 20px 30px 10px; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .menunav > div.full {
        margin: 0 60px; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .menunav > div.full {
        margin: 0 20px; } }
    @media only screen and (min-width: 1600px) {
      .menunav > div.full {
        margin: 30px 120px; } }
  .menunav li {
    display: inline-block;
    margin: 20px 0; }
    @media (max-width: 480px) {
      .menunav li {
        margin: 11px 0; } }
  .menunav li a {
    position: relative; }
    @media (max-width: 480px) {
      .menunav li a {
        margin: 10px 5px; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .menunav li a {
        margin: 10px 10px; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .menunav li a {
        margin: 20px 30px; } }
    @media only screen and (min-width: 1600px) {
      .menunav li a {
        margin: 20px 30px; } }
    @media only screen and (min-width: 1600px) {
      .menunav li a {
        font: 24px/40.8px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .menunav li a {
        font: 20px/34px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .menunav li a {
        font: 15px/25.5px "Karla", Arial, Helvetica, sans-serif; } }
    @media (max-width: 480px) {
      .menunav li a {
        font: 13px/22.1px "Karla", Arial, Helvetica, sans-serif; } }
    .menunav li a:after {
      content: ' ';
      position: absolute;
      display: block;
      z-index: 9999;
      width: 4px;
      height: 4px;
      background: black;
      left: 50%;
      bottom: 0px;
      border-radius: 3px;
      transform: translateY(15px) translateX(-50%) rotateY(90deg);
      transition: all cubic-bezier(0.71, 0.04, 0.32, 0.95) 0.2s; }
  .menunav a.active:after, .menunav a:hover:after {
    background: black;
    transform: translateY(15px) translateX(-50%); }

@media (max-width: 480px) {
  .menus {
    padding: 40px 0 60px; } }

@media only screen and (min-width: 481px) and (max-width: 1000px) {
  .menus {
    padding: 60px 0; } }

@media only screen and (min-width: 1001px) and (max-width: 1599px) {
  .menus {
    padding: 75px 0; } }

@media only screen and (min-width: 1600px) {
  .menus {
    padding: 84px 0; } }

@media (max-width: 480px) {
  .menus .listing {
    padding: 0 30px; } }

@media only screen and (min-width: 481px) and (max-width: 1000px) {
  .menus .listing {
    padding: 0 10%; } }

@media only screen and (min-width: 1001px) and (max-width: 1599px) {
  .menus .listing {
    padding: 0 20%; } }

@media only screen and (min-width: 1600px) {
  .menus .listing {
    padding: 0 30%; } }

@media (max-width: 480px) {
  .menus .listing ul.smaller-starter-plates .price {
    margin-top: -20px; } }

@media only screen and (min-width: 481px) and (max-width: 1000px) {
  .menus .listing ul.smaller-starter-plates .price {
    margin-top: -18px; } }

@media only screen and (min-width: 1001px) and (max-width: 1599px) {
  .menus .listing ul.smaller-starter-plates .price {
    margin-top: -20px; } }

@media only screen and (min-width: 1600px) {
  .menus .listing ul.smaller-starter-plates .price {
    margin-top: -25px; } }

@media (max-width: 480px) {
  .menus .listing ul.eggs-based-dishes .price {
    margin-top: -20px; } }

@media only screen and (min-width: 481px) and (max-width: 1000px) {
  .menus .listing ul.eggs-based-dishes .price {
    margin-top: -18px; } }

@media only screen and (min-width: 1001px) and (max-width: 1599px) {
  .menus .listing ul.eggs-based-dishes .price {
    margin-top: -20px; } }

@media only screen and (min-width: 1600px) {
  .menus .listing ul.eggs-based-dishes .price {
    margin-top: -25px; } }

@media (max-width: 480px) {
  .menus .listing ul.lunchier-dishes .price {
    margin-top: -20px; } }

@media only screen and (min-width: 481px) and (max-width: 1000px) {
  .menus .listing ul.lunchier-dishes .price {
    margin-top: -18px; } }

@media only screen and (min-width: 1001px) and (max-width: 1599px) {
  .menus .listing ul.lunchier-dishes .price {
    margin-top: -20px; } }

@media only screen and (min-width: 1600px) {
  .menus .listing ul.lunchier-dishes .price {
    margin-top: -25px; } }

@media (max-width: 480px) {
  .menus .listing ul.extras .price {
    margin-top: -20px; } }

@media only screen and (min-width: 481px) and (max-width: 1000px) {
  .menus .listing ul.extras .price {
    margin-top: -18px; } }

@media only screen and (min-width: 1001px) and (max-width: 1599px) {
  .menus .listing ul.extras .price {
    margin-top: -20px; } }

@media only screen and (min-width: 1600px) {
  .menus .listing ul.extras .price {
    margin-top: -28px; } }

@media (max-width: 480px) {
  .menus .listing ul.drinks .price {
    margin-top: -22px; } }

@media only screen and (min-width: 481px) and (max-width: 1000px) {
  .menus .listing ul.drinks .price {
    margin-top: -23px; } }

@media only screen and (min-width: 1001px) and (max-width: 1599px) {
  .menus .listing ul.drinks .price {
    margin-top: -23px; } }

@media only screen and (min-width: 1600px) {
  .menus .listing ul.drinks .price {
    margin-top: -27px; } }

.menus .listing li {
  border-top: 1px solid #eeecec; }
  @media (max-width: 480px) {
    .menus .listing li {
      padding: 20px; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .menus .listing li {
      padding: 20px 5%; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .menus .listing li {
      padding: 20px 5%; } }
  @media only screen and (min-width: 1600px) {
    .menus .listing li {
      padding: 20px 5%; } }
  @media only screen and (min-width: 1600px) {
    .menus .listing li {
      font: 24px/28.8px "Karla", Arial, Helvetica, sans-serif; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .menus .listing li {
      font: 20px/24px "Karla", Arial, Helvetica, sans-serif; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .menus .listing li {
      font: 18px/21.6px "Karla", Arial, Helvetica, sans-serif; } }
  @media (max-width: 480px) {
    .menus .listing li {
      font: 18px/21.6px "Karla", Arial, Helvetica, sans-serif; } }
  .menus .listing li:last-child {
    border-bottom: 1px solid #eeecec; }
  .menus .listing li span.title {
    display: block;
    width: 70%; }
  .menus .listing li span.desc {
    color: #2F4F4F;
    display: inline-block;
    width: 55%; }
    @media only screen and (min-width: 1600px) {
      .menus .listing li span.desc {
        font: 18px/23.4px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .menus .listing li span.desc {
        font: 16px/20.8px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .menus .listing li span.desc {
        font: 14px/18.2px "Karla", Arial, Helvetica, sans-serif; } }
    @media (max-width: 480px) {
      .menus .listing li span.desc {
        font: 12px/15.6px "Karla", Arial, Helvetica, sans-serif; } }
  .menus .listing li span.price {
    float: right;
    display: block; }
  .menus .listing li span.empty {
    display: inline; }

.menus div.listing > ul:not(:first-child) {
  display: none;
  list-style-type: none; }

.blog .entry {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  padding: 60px 10vh; }
  @media (max-width: 480px) {
    .blog .entry {
      margin-top: -50px;
      padding: 30px 20px; } }
  .blog .entry .title {
    margin-top: 30px;
    text-align: center; }
    @media only screen and (min-width: 1600px) {
      .blog .entry .title {
        font: 60px/72px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .blog .entry .title {
        font: 50px/60px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .blog .entry .title {
        font: 38px/45.6px "Dala Floda Web", Georgia, serif; } }
    @media (max-width: 480px) {
      .blog .entry .title {
        font: 24px/28.8px "Dala Floda Web", Georgia, serif; } }
  .blog .entry .date {
    text-align: center;
    line-height: 22px;
    margin-top: 10px; }
    @media only screen and (min-width: 1600px) {
      .blog .entry .date {
        font: 24px/28.8px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .blog .entry .date {
        font: 18px/21.6px "Dala Floda Web", Georgia, serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .blog .entry .date {
        font: 18px/21.6px "Dala Floda Web", Georgia, serif; } }
    @media (max-width: 480px) {
      .blog .entry .date {
        font: 14px/16.8px "Dala Floda Web", Georgia, serif; } }
  .blog .entry img.hero {
    display: none;
    max-width: 100%; }
    @media (max-width: 480px) {
      .blog .entry img.hero {
        display: block;
        margin: 0 auto; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .blog .entry img.hero {
        display: block;
        margin: 0 auto; } }

.blog .content {
  padding: 0 20px; }
  @media (max-width: 480px) {
    .blog .content {
      padding-top: 0px; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .blog .content {
      padding-top: 0px; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .blog .content {
      padding-top: 70px; } }
  @media only screen and (min-width: 1600px) {
    .blog .content {
      padding-top: 70px; } }
  @media (max-width: 480px) {
    .blog .content {
      max-width: 100%; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .blog .content {
      max-width: 100%; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .blog .content {
      max-width: 60%; } }
  @media only screen and (min-width: 1600px) {
    .blog .content {
      max-width: 50%; } }
  .blog .content p {
    padding-top: 20px; }
    @media only screen and (min-width: 1600px) {
      .blog .content p {
        font: 24px/33.6px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      .blog .content p {
        font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .blog .content p {
        font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
    @media (max-width: 480px) {
      .blog .content p {
        font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
    .blog .content p a {
      border-bottom: 1px solid #bdbdbd;
      transition: all ease .3s; }
      .blog .content p a:hover {
        border-bottom: 1px solid #000000; }
      .blog .content p a strong {
        font-weight: 400; }

.pagination {
  margin: 0 auto;
  display: block;
  text-align: Center;
  padding-bottom: 60px; }
  .pagination a {
    margin: 0 50px; }
  .pagination.overview {
    width: 100%;
    margin: 0; }

footer {
  background: #231f20;
  position: relative;
  z-index: 2; }
  @media (max-width: 480px) {
    footer {
      padding: 30px 0 0 0; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    footer {
      padding: 60px 0; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    footer {
      padding: 60px 0; } }
  @media only screen and (min-width: 1600px) {
    footer {
      padding: 60px 0; } }
  footer li, footer a {
    color: white;
    text-align: left; }
    @media only screen and (min-width: 1600px) {
      footer li, footer a {
        font: 18px/23.4px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      footer li, footer a {
        font: 16px/20.8px "Karla", Arial, Helvetica, sans-serif; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      footer li, footer a {
        font: 16px/20.8px "Karla", Arial, Helvetica, sans-serif; } }
    @media (max-width: 480px) {
      footer li, footer a {
        font: 16px/20.8px "Karla", Arial, Helvetica, sans-serif; } }
  footer .three {
    margin-top: 0;
    margin-bottom: 0; }
    footer .three div:nth-child(2) {
      display: flex;
      flex-flow: row wrap; }
      footer .three div:nth-child(2) > div {
        flex: 2; }
        footer .three div:nth-child(2) > div:last-child {
          flex: 1; }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      footer .three .details, footer .three div:nth-child(2) {
        flex-basis: 50%; } }
    @media (max-width: 480px) {
      footer .three .email p {
        margin: 0 0 0 20px; } }
    @media (max-width: 480px) {
      footer .three .social li {
        margin: 0 0 0 20px; } }
    footer .three .hours li, footer .three .details li {
      margin-bottom: 20px; }
    @media (max-width: 480px) {
      footer .three .hours, footer .three .social {
        flex: 1 !important; } }
    @media (max-width: 480px) {
      footer .three .details ul {
        display: flex;
        flex-flow: row wrap; }
        footer .three .details ul li {
          flex: 1; } }
    @media (max-width: 480px) {
      footer .three .credit li {
        text-align: left; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      footer .three .credit li {
        text-align: left; } }
    @media only screen and (min-width: 1001px) and (max-width: 1599px) {
      footer .three .credit li {
        text-align: right; } }
    @media only screen and (min-width: 1600px) {
      footer .three .credit li {
        text-align: right; } }
    footer .three .credit a {
      opacity: .4; }

@font-face {
  font-family: 'Dala Floda Web';
  src: url("../fonts/DalaFloda-RomanNo2-Web.eot");
  src: url("../fonts/DalaFloda-RomanNo2-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/DalaFloda-RomanNo2-Web.woff2") format("woff2"), url("../fonts/DalaFloda-RomanNo2-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal; }

.DalaFloda-RomanNo2-Web {
  font-family: 'Dala Floda Web';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal; }

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: local("Karla"), local("Karla-Regular"), url(https://fonts.gstatic.com/s/karla/v5/S1bXQ0LrY7AzefpgNae9sYDGDUGfDkXyfkzVDelzfFk.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: local("Karla"), local("Karla-Regular"), url(https://fonts.gstatic.com/s/karla/v5/JaMH4jmmzP070-OYo03anaCWcynf_cDxXwCLxiixG1c.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.entry .entry-title {
  margin-top: 50px;
  margin-bottom: 50px; }

.entry .entry-image {
  display: block;
  text-align: center; }
  .entry .entry-image .hero {
    max-width: 60%; }
    @media (max-width: 480px) {
      .entry .entry-image .hero {
        max-width: 100%; } }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .entry .entry-image .hero {
        max-width: 60%; } }

.entry .entry-content {
  text-align: center; }
  .entry .entry-content .entry-block {
    display: inline-block;
    text-align: left;
    margin-left: 15vw;
    margin-right: 15vw; }
    @media only screen and (min-width: 481px) and (max-width: 1000px) {
      .entry .entry-content .entry-block {
        margin-left: 15vw;
        margin-right: 15vw; } }
    .entry .entry-content .entry-block p:first-child {
      padding-top: 50px; }
    .entry .entry-content .entry-block p:last-child {
      padding-bottom: 120px; }
    .entry .entry-content .entry-block p {
      margin-top: 20px; }
      @media only screen and (min-width: 1600px) {
        .entry .entry-content .entry-block p {
          font: 24px/33.6px "Karla", Arial, Helvetica, sans-serif; } }
      @media only screen and (min-width: 1001px) and (max-width: 1599px) {
        .entry .entry-content .entry-block p {
          font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
      @media only screen and (min-width: 481px) and (max-width: 1000px) {
        .entry .entry-content .entry-block p {
          font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
      @media (max-width: 480px) {
        .entry .entry-content .entry-block p {
          font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
      .entry .entry-content .entry-block p a {
        border-bottom: 1px solid #bdbdbd;
        transition: all ease .3s; }
        .entry .entry-content .entry-block p a:hover {
          border-bottom: 1px solid #000000; }
        .entry .entry-content .entry-block p a strong {
          font-weight: 400; }
    .entry .entry-content .entry-block ul li {
      list-style-type: square;
      margin-left: 50px; }
      @media only screen and (min-width: 1600px) {
        .entry .entry-content .entry-block ul li {
          font: 24px/33.6px "Karla", Arial, Helvetica, sans-serif; } }
      @media only screen and (min-width: 1001px) and (max-width: 1599px) {
        .entry .entry-content .entry-block ul li {
          font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
      @media only screen and (min-width: 481px) and (max-width: 1000px) {
        .entry .entry-content .entry-block ul li {
          font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
      @media (max-width: 480px) {
        .entry .entry-content .entry-block ul li {
          font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
    .entry .entry-content .entry-block ul li:first-child {
      margin-top: 20px; }

.entry .content ul {
  margin-top: 20px;
  margin-bottom: 15px; }

.entry .content li {
  list-style-type: circle; }
  @media only screen and (min-width: 1600px) {
    .entry .content li {
      font: 24px/33.6px "Karla", Arial, Helvetica, sans-serif; } }
  @media only screen and (min-width: 1001px) and (max-width: 1599px) {
    .entry .content li {
      font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
  @media only screen and (min-width: 481px) and (max-width: 1000px) {
    .entry .content li {
      font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
  @media (max-width: 480px) {
    .entry .content li {
      font: 16px/22.4px "Karla", Arial, Helvetica, sans-serif; } }
